import { Component,OnInit  } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Signin, Signup } from '../../models/signin';
import { AuthService } from "../../services/auth.service";
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-signup-page',
  templateUrl: './signup-page.component.html',
  styleUrl: './signup-page.component.scss'
})
export class SignupPageComponent implements OnInit {
    private data!: Signin;
    private singinRes:any;
    private action: string = '';
    signupForm: FormGroup;
    errorMessage: string = '';
    successMessage: string = '';
    passwordFieldType: string = 'password';
    repeatPasswordFieldType: string = 'password';
    showPasswordIcon: string = 'assets/images/hide-password.svg';
    showRepeatPasswordIcon: string = 'assets/images/hide-password.svg';

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService
      ) {
        this.signupForm = this.fb.group({});
      }

      ngOnInit() {
          const actionParam = this.route.snapshot.paramMap.get('action'); // Obtener el parámetro una vez
          this.action =  actionParam ? actionParam : '';
          this.createForm();
      }

      createForm() {
        const today = new Date().toISOString().split('T')[0];
        this.signupForm = this.fb.group({
          email: ['', [Validators.required, Validators.email]],
          firstName: ['', Validators.required],
          lastName: ['', Validators.required],
          birthday: [today, Validators.required],
          phone: ['', Validators.required],
          password: ['', [Validators.required, Validators.minLength(6)]],
          repeatPassword: ['', [Validators.required, Validators.minLength(6)]],
          actionSignup: [this.action]
        });
      }

      signup() {
        if (this.signupForm.valid) {
          const formData = this.signupForm.value;
          console.log('Datos del form: ',formData);
          this.authService.signup(formData).subscribe({
            next: (response: any) => {
                this.successMessage = 'Registro Exitoso';
                this.showError();
                console.log('Registro exitoso', response);
                    // Puedes redirigir a otra página u hacer otras acciones después del registro exitoso
                this.router.navigate(['/success']);
            },
            error: (error: any) => {
                this.errorMessage = error;
                this.showError();
                // this.errorMessage = 'Error al registrar. Por favor, inténtelo de nuevo.';
            }
          });
        } else {
            this.errorMessage = 'Por favor, complete todos los campos correctamente.';
            this.showError();
            this.validateAllFormFields(this.signupForm);
        }
      }
      
      validateAllFormFields(formGroup: FormGroup) {
          Object.keys(formGroup.controls).forEach(field => {
              const control = formGroup.get(field);
              if (control instanceof FormGroup) {
                  this.validateAllFormFields(control);
                } else {
                    control?.markAsTouched({ onlySelf: true });
                }
            });
        }
        showError() {
            setTimeout(() => {
              this.errorMessage = '';
              this.successMessage = '';
            }, 3000);
    }

    togglePasswordVisibility(field: 'password' | 'repeatPassword') {
        if (field === 'password') {
          this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
          this.showPasswordIcon = this.passwordFieldType === 'password' ? 'assets/images/hide-password.svg' : 'assets/images/show-password.svg';
        } else {
          this.repeatPasswordFieldType = this.repeatPasswordFieldType === 'password' ? 'text' : 'password';
          this.showRepeatPasswordIcon = this.repeatPasswordFieldType === 'password' ? 'assets/images/hide-password.svg' : 'assets/images/show-password.svg';
        }
      }
    }
