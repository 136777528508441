<div class="background">
    <div class="landing-header text-center">
        <h1 class="nextly">Próximamente</h1>
        <img src="../assets/images/vivo-logo.png" class="img-fluid my-3">
        <p class="paragrap">El lugar donde encontrarás <b>la vivienda ideal para ti</b></p>
        <p class="third"><b>¿Qué buscas?</b></p>
    </div>
    <div class="buttons d-flex flex-wrap justify-content-center">
        <button class="btn btn-primary btn-text m-2" (click)="onButtonClick('TO_RENT')" value="Rentar">Rentar</button>
        <button class="btn btn-primary btn-text m-2" (click)="onButtonClick('TO_BUY')" value="Comprar">Comprar</button>
        <button class="btn btn-primary btn-text m-2" (click)="onButtonClick('TO_SELL')" value="Vender">Vender</button>
    </div>
</div>
