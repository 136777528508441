<div class="signin-container">
    <img src="assets/images/linea2.svg" alt="line" class="logo-linea">
    <div *ngIf="errorMessage" class="alert alert-danger">
        {{ errorMessage }}
    </div>
    <div *ngIf="successMessage" class="alert alert-danger">
        {{ successMessage }}
    </div>
    <div class="row justify-content-center">
        <img src="assets/images/vivologo.svg" alt="logo" class="logo-vivo">
        <div class="text-container">
            <div class="line text">Iniciar Sesión </div>
        </div>
        <div class="row">
            <form [formGroup]="signinForm" (ngSubmit)="signup()" class="login">
                <div class="form-group">
                    <input type="text" formControlName="email" class="form-control mb-4" placeholder="Correo Electrónico">
                    <div class="error-message" *ngIf="signinForm.get('email')?.invalid && signinForm.get('email')?.touched" class="text-danger">
                    Ingrese un correo electrónico válido.
                </div>
            </div>
            <div class="form-group position-relative">
                <div class="input-group">
                    <input [type]="passwordFieldType" formControlName="password" class="form-control mb-4" placeholder="Contraseña">
                    <button type="button" class="btn btn-link position-absolute end-0 top-50 translate-middle-y me-2" (click)="togglePasswordVisibility('password')">
                        <img [src]="showPasswordIcon" alt="Toggle password visibility">
                    </button>
                </div>
                <div class="error-message" *ngIf="signinForm.get('password')?.invalid && signinForm.get('password')?.touched" class="text-danger">
                Mínimo 6 caracteres.
                </div>
            </div>
                <button type="submit" class="btn-secondary btn-text" value="Vender">Iniciar Sesión</button>
            </form>
        </div>
    </div>

</div>