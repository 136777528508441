<div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12">
        <img src="/assets/images/shutterstock_433992616.jpg" alt="img" class="img">
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 form">
        <div class="text1">
            INICIAR SESIÓN
        </div>
        <div>
            <label class="account">¿Aún no tienes una cuenta?</label><button class="button-register">Regístrate</button>
        </div>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <input type="email" id="email" placeholder="Correo electronico" class="form-control imput-field" formControlName="email">     
            </div>
            <div class="form-group">
                </div>
            <div class="form-group">
                <div class="input-group">
                    <input [type]="hidePassword ? 'password' : 'text'" id="password" placeholder="Contraseña" class="form-control imput-field" formControlName="password"> 
                        <div>
                            <button type="button"  (click)="togglePasswordVisibility()" class="form-control input-group-append">
                                <i *ngIf="hidePassword" class="bi bi-eye-fill"></i>
                                <i *ngIf="!hidePassword" class="bi bi-eye-slash-fill"></i>
                            </button>
                        </div>
                    </div>
              </div>
            <div class="option-password">
                <label class="remember-me"><input type="checkbox">Recuérdame</label>
                <button class="button-forget-password">¿Olvidaste tu contraseña?</button> 
            </div>
            <div>
                <button class="button-login">Iniciar Sesion</button>
            </div>
        </form>
    </div>
</div>