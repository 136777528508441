import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { LandingPageComponent } from './landing/landing-page/landing-page.component';
import { SigninPageComponent } from './landing/signin-page/signin-page.component';
import { SignupPageComponent } from './landing/signup-page/signup-page.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { CardsComponent } from './components/cards/cards.component';
import { HomePageComponent } from './home-page/home-page.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { SignupSuccesComponent} from './landing/signup-succes/signup-succes.component';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';

const routes: Routes = [
    { path: '', component: LandingPageComponent },
    //{ path: 'signin', component: SigninPageComponent },
    { path: 'signup', component: SignupPageComponent },
    { path: 'signup/:action', component: SignupPageComponent },
    { path: 'header', component: HeaderComponent},
    { path: 'footer', component: FooterComponent},
    { path: 'cards', component: CardsComponent},
    { path: 'home-page', component: HomePageComponent},
    { path: 'search-bar', component: SearchBarComponent},
    { path: 'success', component: SignupSuccesComponent },
    { path: 'login', component: LoginComponent },
    { path: 'login-signup', component: SignupComponent },
    { path: '**', redirectTo: ''},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
