import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environtments/env';
import { Signup } from '../models/signin';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private env: any;

    constructor(private http: HttpClient) {
    this.env = environment;
    }

    public signup(data: Signup): Observable<any> {
      const api = `${this.env.api}/account-manager/signup`;
      return this.http.post(api, data).pipe(
        catchError((error: HttpErrorResponse) => this.handleError(error))
      );
    }
    
    private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = this.getServerErrorMessage(error);
    }
    return throwError(errorMessage);
    }

    private getServerErrorMessage = (error: HttpErrorResponse): string => {
    console.log('Error from server:', error.status);  
    switch (error.status) {
      case 400: {
      const errorMessage = error.error?.message;
        if (errorMessage === 'Email already exists.') {
          return (error.error.message||'Correo electrónico ya registrado');
        } else if (errorMessage === 'password does not match.') {
          return error.error.message;//'La contraseña no coincide';
        } else {
          return 'Error en la solicitud';
        }
      }
      case 401: {
        return 'Unauthorized: ' + (error.error?.message || 'Authentication failed.');
      }
      case 404: {
        return 'Not Found: ' + (error.error?.message || 'Resource not found.');
      }
      case 500: {
        return 'Internal Server Error: ' + (error.error?.message || 'An unexpected error occurred.');
      }
      default: {
        return `Unexpected error: ${error.message}`;
      }
    }
    }
}