<div class="signup-container">
    <img src="assets/images/linea2.svg" alt="line" class="logo-linea">
    <img src="assets/images/linea-naranja.svg" alt="line" class="linea-naranja">
    <img src="assets/images/linea1.svg" alt="line" class="logo-linea1">
    <div class="row justify-content-center">
        <img src="assets/images/check-success.svg" alt="logo" class="img-fluid logo-check">
        <div class="text-container">
            <div class="line large-text">¡Gracias por</div>
            <div class="line large-text">registrarte!</div>
            <div class="line medium-text">ERES UN CLIENTE PREMIUM</div>
            <div class="line small-text">Estaremos enviando un <strong>correo electrónico</strong> para comunicarnos</div>
            <div class="line small-text">contigo y ayudarte a encontrar lo que necesitas</div>
        </div>
    <img src="assets/images/arrow-left.svg" alt="flecha" class="arrow-left"> 
    <div class="row justify-content-center">
    <button type="submit" class="btn-secondary btn-text" (click)="onButtonClick()"value="Vender">Regresar al Inicio</button>      
    </div> 
    </div>
</div>
