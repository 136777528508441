import { Component } from '@angular/core';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrl: './search-bar.component.scss'
})
export class SearchBarComponent {
  rangeMin = 0;
  rangeMax = 1000;
  rangeValue = 0;

  constructor() {}

  updateRange() {
    this.rangeMin = this.rangeValue;
    this.rangeMax = 1000 - this.rangeValue;
  }

}