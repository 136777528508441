import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit{

  ngOnInit(): void {
    
  }

  onButtonClick(buttonName: string): void {
  console.log (`${buttonName}`);
  }

  onSearchButtonClick(){
    
  }
  
}
