<div class="signup-container">
    <img src="assets/images/linea2.svg" alt="line" class="logo-linea">
    <div *ngIf="errorMessage" class="alert alert-danger alert-dismissible fade show" role="alert">
        {{ errorMessage }}
        <button type="button" class="btn-close" aria-label="Close" (click)="errorMessage=''"></button>
    </div>
    <div *ngIf="successMessage" class="alert alert-success alert-dismissible fade show" role="alert">
        {{ successMessage }}
        <button type="button" class="btn-close" aria-label="Close" (click)="successMessage=''"></button>
    </div>
    <div class="row justify-content-center">
        <img src="assets/images/vivologo.svg" alt="logo" class="logo-vivo">
        <h1>REGÍSTRATE</h1>
        <div class="row">
            <form [formGroup]="signupForm" (ngSubmit)="signup()" class="register">
                <div class="form-group">
                    <input type="text" formControlName="email" class="form-control mb-1" placeholder="Correo Electrónico">
                    <div class="error-message" *ngIf="signupForm.get('email')?.invalid && signupForm.get('email')?.touched" class="text-danger">
                    Ingrese un correo electrónico válido.
                </div>
            </div>
            <div class="form-group">
                <input type="text" formControlName="firstName" class="form-control mb-1" placeholder="Nombre">
                <div class="error-message" *ngIf="signupForm.get('firstName')?.invalid && signupForm.get('firstName')?.touched" class="text-danger">
                    Este campo es obligatorio.
                </div>
            </div>
            <div class="form-group">
                <input type="text" formControlName="lastName" class="form-control mb-1" placeholder="Apellido">
                <div class="error-message" *ngIf="signupForm.get('lastName')?.invalid && signupForm.get('lastName')?.touched" class="text-danger">
                    Este campo es obligatorio.
                </div>
            </div>
            <div class="form-group">
                <input type="date" formControlName="birthday" class="form-control mb-1" placeholder="Fecha de Nacimiento">
                <div class="error-message" *ngIf="signupForm.get('birthday')?.invalid && signupForm.get('birthday')?.touched" class="text-danger">
                    Este campo es obligatorio.
                </div>
            </div>
            <div class="form-group">
                <input type="text" formControlName="phone" class="form-control mb-1" placeholder="Número de teléfono">
                <div class="error-message" *ngIf="signupForm.get('phone')?.invalid && signupForm.get('phone')?.touched" class="text-danger">
                    Este campo es obligatorio.
                </div>
            </div>
            <div class="form-group position-relative">
                <div class="input-group">
                    <input [type]="passwordFieldType" formControlName="password" class="form-control mb-1" placeholder="Contraseña">
                    <button type="button" class="btn btn-link position-absolute end-0 top-50 translate-middle-y me-2" (click)="togglePasswordVisibility('password')">
                        <img [src]="showPasswordIcon" alt="Toggle password visibility">
                    </button>
                </div>
                <div class="error-message" *ngIf="signupForm.get('password')?.invalid && signupForm.get('password')?.touched" class="text-danger">
                Mínimo 6 caracteres.
                </div>
            </div>
            <div class="form-group position-relative">
                <div class="input-group">
                <input [type]="repeatPasswordFieldType" formControlName="repeatPassword" class="form-control mb-1" placeholder="Confirmar contraseña">
                <button type="button" class="btn btn-link position-absolute end-0 top-50 translate-middle-y me-2" (click)="togglePasswordVisibility('repeatPassword')">
                    <img [src]="showRepeatPasswordIcon" alt="Toggle repeat password visibility">
                </button>
                </div>
                <div class="error-message" *ngIf="signupForm.get('repeatPassword')?.invalid && signupForm.get('repeatPassword')?.touched" class="text-danger">
                Mínimo 6 caracteres.
                </div>
            </div>
                <button type="submit" class="btn-secondary btn-text" value="Vender">Registrarme</button>
            </form>
        </div>
    </div>
</div>