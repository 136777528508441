<div class="cards">
    <div class="container">
        <div class="box">
            <img src="/assets/images/Icon awesome-check-circle.svg" alt="Seguro y confiable">
            <p>Seguro y confiable</p>
        </div>
        <div class="box">
            <img src="/assets/images/Grupo 20.svg" alt="Comodidad y calidad">
            <p>Comodidad y calidad</p>
        </div>
        <div class="box">
            <img src="/assets/images/Icon awesome-building.svg" alt="Facilidad inmobiliaria">
            <p>Facilidad inmobiliaria</p>
        </div>
    </div>
</div>