<div class="signup-container">
    <div *ngIf="errorMessage" class="alert alert-danger alert-dismissible fade show" role="alert">
        {{ errorMessage }}
        <button type="button" class="btn-close" aria-label="Close" (click)="errorMessage=''"></button>
    </div>
    <h2 class="title">REGÍSTRATE</h2>
    <p class="signin-redirect">¿Ya tienes una cuenta en vivo? <a routerLink="/signin">Iniciar Sesión</a></p>
    <form [formGroup]="signupForm" (ngSubmit)="signup()">
        <div class="form-group">
                <input type="text" formControlName="email" class="form-control" placeholder="Correo Electrónico">
            <div class="error-message" *ngIf="signupForm.get('email')?.invalid && signupForm.get('email')?.touched" class="text-danger">
            Ingrese un correo electrónico válido.
        </div>
        </div>
        <div class="form-group row">
            <div class="col">
                <input type="text" formControlName="firstName" class="form-control" placeholder="Nombre">
                <div class="error-message" *ngIf="signupForm.get('firstName')?.invalid && signupForm.get('firstName')?.touched" class="text-danger">
                    Este campo es obligatorio.
                </div>
            </div>
            <div class="col">
                <input type="text" formControlName="lastName" class="form-control" placeholder="Apellido">
                <div class="error-message" *ngIf="signupForm.get('lastName')?.invalid && signupForm.get('lastName')?.touched" class="text-danger">
                    Este campo es obligatorio.
                </div>
            </div>
        </div>
        <div class="form-group">
            <input type="date" formControlName="birthday" class="form-control" placeholder="Fecha de Nacimiento">
                <div class="error-message" *ngIf="signupForm.get('birthday')?.invalid && signupForm.get('birthday')?.touched" class="text-danger">
                    Este campo es obligatorio.
                </div>
        </div>
        <div class="form-group row">
        <div class="col">
        <select class="form-select" aria-label="select" formControlName="action" >
            <option value="" class="option" selected disabled>¿Qué buscas?</option>
            <option value="TO_RENT"  class="option">Rentar</option>
            <option value="TO_BUY" class="option">Comprar</option>
            <option value="TO_SELL" class="option">Vender</option>
          </select>
        <div class="error-message" *ngIf="signupForm.get('firstName')?.invalid && signupForm.get('firstName')?.touched" class="text-danger">
            Este campo es obligatorio.
        </div>
        </div>
        <div class="col">
            <input type="text" formControlName="phone" class="form-control mb-4" placeholder="Número de teléfono">
                <div class="error-message" *ngIf="signupForm.get('phone')?.invalid && signupForm.get('phone')?.touched" class="text-danger">
                    Este campo es obligatorio.
                </div>
        </div>
        </div>
        <div class="form-group">
            <div class="input-group">
                <input [type]="showPassword ? 'text' : 'password'" formControlName="password" class="form-control" placeholder="Contraseña">  
                <div class="input-group-append">
                    <button type="button" class="toggle-password" (click)="togglePasswordVisibility('password')">
                        <img src="assets/images/hide-password.svg" alt="Mostrar Contraseña" *ngIf="!showPassword">
                        <img src="assets/images/show-password.svg" alt="Ocultar Contraseña" *ngIf="showPassword">
                    </button>
                </div>
            </div>
            <div class="error-message" *ngIf="signupForm.get('password')?.invalid && signupForm.get('password')?.touched" class="text-danger">
                Mínimo 6 caracteres.
                </div>
        </div>
        <div class="form-group">
            <div class="input-group">
                <input [type]="showRepeatPassword ? 'text' : 'password'" formControlName="repeatPassword" class="form-control" placeholder="Confirmar Contraseña">
                <div class="input-group-append">
                    <button type="button" class="toggle-password" (click)="togglePasswordVisibility('repeatPassword')">
                        <img src="assets/images/hide-password.svg" alt="Mostrar Contraseña" *ngIf="!showRepeatPassword">
                        <img src="assets/images/show-password.svg" alt="Ocultar Contraseña" *ngIf="showRepeatPassword">
                    </button>
                </div>
            </div>
            <div class="error-message" *ngIf="signupForm.get('repeatPassword')?.invalid && signupForm.get('repeatPassword')?.touched" class="text-danger">
                Mínimo 6 caracteres.
            </div>
        </div>
        <p class="terms">Al registrarse con nosotros acepta los <a routerLink="/terms-and-conditions">términos y condiciones</a></p>
        <button type="submit" class="btn btn-primary">Registrarme</button>
    </form>
</div>
