<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <div>
        <app-header ></app-header>
    </div>
    <div class="home-container">
        <img src="/assets/images/woman-wearing-headphones-full-shot.jpg">
        <div class="cards-container">
            <app-cards></app-cards>
        </div>
        <app-footer></app-footer>
    </div>
    <br>
    <br>
    <br>

</body>
<footer>
    
</footer>
</html>