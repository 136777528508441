import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-signin-page',
  //standalone: true,
  //imports: [NgbModule],
  templateUrl: './signin-page.component.html',
  styleUrl: './signin-page.component.scss'
})

export class SigninPageComponent implements OnInit {
    private signinRes:any;
    private action: string = '';
    signinForm: FormGroup;
    errorMessage: string = '';
    successMessage: string = '';
    passwordFieldType: string = 'password';
    showPasswordIcon: string = 'assets/images/hide-password.svg';
    
    constructor(
      private fb: FormBuilder,
      private router: Router,
      private route: ActivatedRoute,
      private authService: AuthService
    ){
      this.signinForm = this.fb.group({});
    }

    ngOnInit() {
      this.createForm();
      const actionParam = this.route.snapshot.paramMap.get('action'); // Obtener el parámetro una vez
    }

    createForm() {
      this.signinForm = this.fb.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(6)]]
      });
    }

    signup() {
      if (this.signinForm.valid) {
        const formData = this.signinForm.value;
        console.log('Datos del form: ',formData);
        this.authService.signup(formData).subscribe({
          next: (response: any) => {
              this.successMessage = 'Registro Exitoso';
              this.showError();
              console.log('Registro exitoso', response);
                  // Puedes redirigir a otra página u hacer otras acciones después del registro exitoso
              this.router.navigate(['/success']);
          },
          error: (error: any) => {
              this.errorMessage = error;
              this.showError();
              // this.errorMessage = 'Error al registrar. Por favor, inténtelo de nuevo.';
          }
        });
      } else {
          this.errorMessage = 'Por favor, complete todos los campos correctamente.';
          this.showError();
          this.validateAllFormFields(this.signinForm);
      }
    }

    validateAllFormFields(formGroup: FormGroup) {
      Object.keys(formGroup.controls).forEach(field => {
          const control = formGroup.get(field);
          if (control instanceof FormGroup) {
              this.validateAllFormFields(control);
            } else {
                control?.markAsTouched({ onlySelf: true });
            }
        });
    }
    showError() {
        setTimeout(() => {
          this.errorMessage = '';
          this.successMessage = '';
        }, 3000);
    }

    togglePasswordVisibility(field: 'password' | 'repeatPassword') {
      if (field === 'password') {
        this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
        this.showPasswordIcon = this.passwordFieldType === 'password' ? 'assets/images/hide-password.svg' : 'assets/images/show-password.svg';
      }
    }
    
  }