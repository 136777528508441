import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup-succes',
  templateUrl: './signup-succes.component.html',
  styleUrl: './signup-succes.component.scss'
})
export class SignupSuccesComponent {

  constructor (private router: Router) {}
  
  onButtonClick(): void {
    this.router.navigate(['']);
  }

}
