import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './landing/landing-page/landing-page.component';
import { SignupPageComponent } from './landing/signup-page/signup-page.component';
import { HeaderComponent } from './components/header/header.component';
import { AuthService } from './services/auth.service';
import { HomePageComponent } from './home-page/home-page.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { SignupSuccesComponent } from './landing/signup-succes/signup-succes.component';
import { FooterComponent } from './components/footer/footer.component';
import { CardsComponent } from './components/cards/cards.component';
import { LoginComponent } from './components/login/login.component';
import { SigninPageComponent } from './landing/signin-page/signin-page.component';
import { SignupComponent } from './components/signup/signup.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    SignupPageComponent,
    SigninPageComponent,
    HeaderComponent,
    FooterComponent,
    CardsComponent,
    HomePageComponent,
    SearchBarComponent,
    SignupSuccesComponent,
    LoginComponent,
    SignupComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule
  ],
  providers: [
    AuthService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
