import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
    signupForm: FormGroup;
    errorMessage: string = '';
    showPassword: boolean = false;
    showRepeatPassword: boolean = false;

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService
    ) {
        this.signupForm = this.fb.group({});
    }

    ngOnInit() {
        this.createForm();
    }

    createForm() {
        const today = new Date().toISOString().split('T')[0];
        this.signupForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            birthday: [today, Validators.required],
            phone: ['', Validators.required],
            password: ['', [Validators.required, Validators.minLength(6)]],
            repeatPassword: ['', [Validators.required, Validators.minLength(6)]],
            action: ['',Validators.required]
        });
    }

    signup() {
        if (this.signupForm.valid) {
            const formData = this.signupForm.value;
            console.log('Datos del form: ',formData);
            this.authService.signup(formData).subscribe({
                next: (response: any) => {
                    console.log('Registro exitoso', response);
                    this.errorMessage = 'Registro exitoso';
                    this.router.navigate(['/home-page']);
                },
                error: (error: any) => {
                    this.errorMessage = error;
                    this.showError();
                }
            });
        } else {
            this.errorMessage = 'Por favor, complete todos los campos correctamente.';
            this.showError();
            this.validateAllFormFields(this.signupForm);
        }
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            } else {
                control?.markAsTouched({ onlySelf: true });
            }
        });
    }

    showError() {
        setTimeout(() => {
            this.errorMessage = '';
        }, 3000);
    }

    togglePasswordVisibility(controlName: 'password' | 'repeatPassword') {
        if (controlName === 'password') {
            this.showPassword = !this.showPassword;
        } else if (controlName === 'repeatPassword') {
            this.showRepeatPassword = !this.showRepeatPassword;
        }
    }
}
