<nav class="navbar navbar-expand-md">
    <a class="navbar-brand" href="#">
      <img src="/assets/images/vivologo.svg" alt="Logo" class="navbar-logo">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav justify-content-center">
        <li class="nav-item">
          <button class="nav-link custom-button" (click)="onButtonClick('Rentar')">Rentar</button>
        </li>
        <li class="nav-item">
          <button class="nav-link custom-button" (click)="onButtonClick('Comprar')">Comprar</button>
        </li>
        <li class="nav-item">
          <button class="nav-link custom-button" (click)="onButtonClick('Vender')">Vender</button>
        </li>
        <li class="nav-item">
          <button class="nav-link custom-button" (click)="onButtonClick('Planes')">Planes</button>
        </li>
        <li class="nav-item">
          <button class="nav-link custom-button" (click)="onButtonClick('Ayuda')">Ayuda</button>
        </li>
        <li class="nav-item">
          <button class="nav-link custom-button" (click)="onButtonClick('Login')">Iniciar sesión</button>
        </li>
        <li class="nav-item">
          <button class="nav-link register-button custom-button" (click)="onButtonClick('Registrate')">Regístrate</button>
        </li>
        <li class="nav-item">
          <button class="nav-link search-button" (click)="onSearchButtonClick()">
            <img src="/assets/images/Icon metro-search.svg" alt="Buscar">
          </button>
        </li>
      </ul>
    </div>
  </nav>
  