<footer row>
    <div class="footer container-fluid py-3">
      <div class="row text-center">
        <div class="footer-column footer-column-1 col-xl-3 col-lg-4 col-md-12 mb-4 mb-lg-0 text-center d-flex align-items-center justify-content-center">
          <div>
          <img src="/assets/images/vivologo.svg" alt="Logo" class="footer-logo mb-3">
          <div>
            <button class="social-media">
              <img src="/assets/images/Icon awesome-facebook.svg" alt="Logo" class="media">
            </button>
            <button class="social-media">
              <img src="/assets/images/Icon awesome-instagram.svg" alt="Logo" class="media">
            </button>
            <button class="social-media">
              <img src="/assets/images/Icon awesome-youtube.svg" alt="Logo" class="media">
            </button>
          </div>
          </div>
        </div>
        <div class="footer-column footer-column-2 col-xl-3 col-lg-4 col-md-6 mb-4 mb-lg-0 text-center text-md-left">
          <button class="custom-button">¿Quiénes Somos?</button>
          <button class="custom-button">Rentar</button>
          <button class="custom-button">Comprar</button>
          <button class="custom-button">Vender</button>
        </div>
        <div class="footer-column footer-column-3 col-xl-3 col-lg-4 col-md-6 mb-4 mb-lg-0 text-md-left text-center">
          <button class="custom-button">Planes</button>
          <button class="custom-button">Ayuda</button>
          <button class="custom-button">Términos y condiciones</button>
          <button class="custom-button">FAQs</button>
        </div>
        <div class="footer-column footer-column-4 col-xl-3 col-lg-12 col-md-12 mb-4 mb-lg-0 text-center text-md-left d-flex align-items-center justify-content-center">
           <div>
            <p>Obten noticias de las nuevas propiedades</p>
            <div class="d-flex">
              <input type="email" placeholder="Correo Electrónico" class="registration-input">
              <button class="registration-button">Suscribete</button>
            </div>
          </div>
        </div>
        <div class="Copyright-footer text-center py-3">
          <p class="Copyright mb-0">Copyright ©Vivo 2024. Todos los derechos reservados</p>
        </div>  
      </div>
    </div>
  </footer>
  